<template>
    <div v-loading="loading" class="app-container">
        <div v-if="isPending" class="user-content">
            <user-profile :user="userData" @saved="handleProfileSaved" />
        </div>
        <div v-else class="user-content">
            <template v-if="!loading && isValidUser">
                <plan-panel
                    v-if="user.plans && user.plans.length"
                    read-only
                    :show-name="!isAccountUser"
                    :user="user"
                />
                <div v-else class="user-notice">
                    {{ user.name }} does not have any plans defined.
                </div>
            </template>
            <template v-else>
                <div
                    v-if="isExternal && !managedUsers.length"
                    class="user-notice"
                >
                    You do not have any users assigned to your account.
                </div>
                <div v-else-if="error" class="user-notice">
                    <el-alert
                        class="error-message"
                        type="error"
                        title="Unable to display the selected user account."
                        :closable="false"
                        >{{ error }}</el-alert
                    >
                </div>
                <div v-else class="user-notice">Loading user account...</div>
            </template>
        </div>
    </div>
</template>

<script>
import * as Sentry from '@sentry/browser';
import auth from '@/utils/auth';
import PlanPanel from '@/views/components/PlanPanel';
import UserProfile from './UserProfile';

export default {
    name: 'client',
    components: {
        PlanPanel,
        UserProfile,
    },
    props: {},
    data() {
        return {
            user: {
                ndis_number: null,
                name: null,
                code: null,
                mobile: null,
                phone: null,
                dob: null,
                address: null,
                contact_email: null,
            },
            loading: true,
            error: null,
            refreshKey: 1,
        };
    },
    computed: {
        userData() {
            const userData = {...auth.userData};
            userData.id = auth.user.uid;
            return userData;
        },
        isExternal() {
            return auth.role === auth.ROLE_EXTERNAL;
        },
        isPending() {
            this.refreshKey;
            return auth.userData.status === 'pending';
        },
        managedUsers() {
            return auth.userData.managedUsers;
        },
        isValidUser() {
            if (this.user && !!this.user.ndis_number) {
                if (this.user.id === auth.user.uid) return true;
                // check if this user is in the authed user's managed list
                for (const usr of auth.userData.managedUsers) {
                    if (this.user.id === usr.id) return true;
                }
                return false;
            }
            return false;
        },
        isAccountUser() {
            return (
                this.isValidUser &&
                !this.isExternal &&
                this.user.ndis_number === auth.userData.ndis_number
            );
        },
    },
    watch: {
        $route: function (val) {
            console.log('CHANGE ROUTE');
            this.bindUser();
        },
        isValidUser(isValid) {
            if (!isValid && this.user && !!this.user.ndis_number) {
                this.error = 'You do not have access to this account.';
            }
        },
    },
    mounted() {
        this.bindUser();
    },
    methods: {
        bindUser() {
            this.loading = true;
            let user_id = auth.user.uid || null;
            if (this.$route.params.user_id !== undefined) {
                user_id = this.$route.params.user_id;
            }
            if (user_id) {
                this.$bind('user', this.$fire.doc(`users/${user_id}`), {
                    maxRefDepth: 1,
                })
                    .then((user) => {
                        this.loading = false;
                        if (user === null) {
                            this.error = 'The user was not found.';
                        }
                    })
                    .catch((e) => {
                        console.log('User error: ', e);
                        this.error = e;
                        this.$notify.error({
                            title: 'Error',
                            message: 'Unable to load user profile',
                        });
                        this.loading = false;
                        Sentry.captureException(e);
                    });
            } else {
                this.error = 'No user specified';
                this.user = {
                    id: null,
                    ndis_number: null,
                    name: null,
                    code: null,
                    mobile: null,
                    phone: null,
                    dob: null,
                    address: null,
                    contact_email: null,
                };
                this.loading = false;
            }
        },
        handleProfileSaved(user) {
            console.log('Saved user', user);
            auth.userData.status = 'active';
            this.refreshKey++;
            this.$bus.$emit('refreshAuth', {});
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-content {
    max-width: 900px;
    margin: 0 auto;
}
.user-notice {
    padding-top: 4em;
    text-align: center;
    .error-message {
        text-align: left;
        margin: 20px 0;
        border: 1px solid $red;
        padding: 15px 20px;
    }
}
</style>
