var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _vm.isPending
        ? _c(
            "div",
            { staticClass: "user-content" },
            [
              _c("user-profile", {
                attrs: { user: _vm.userData },
                on: { saved: _vm.handleProfileSaved },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "user-content" },
            [
              !_vm.loading && _vm.isValidUser
                ? [
                    _vm.user.plans && _vm.user.plans.length
                      ? _c("plan-panel", {
                          attrs: {
                            "read-only": "",
                            "show-name": !_vm.isAccountUser,
                            user: _vm.user,
                          },
                        })
                      : _c("div", { staticClass: "user-notice" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.user.name) +
                              " does not have any plans defined. "
                          ),
                        ]),
                  ]
                : [
                    _vm.isExternal && !_vm.managedUsers.length
                      ? _c("div", { staticClass: "user-notice" }, [
                          _vm._v(
                            " You do not have any users assigned to your account. "
                          ),
                        ])
                      : _vm.error
                      ? _c(
                          "div",
                          { staticClass: "user-notice" },
                          [
                            _c(
                              "el-alert",
                              {
                                staticClass: "error-message",
                                attrs: {
                                  type: "error",
                                  title:
                                    "Unable to display the selected user account.",
                                  closable: false,
                                },
                              },
                              [_vm._v(_vm._s(_vm.error))]
                            ),
                          ],
                          1
                        )
                      : _c("div", { staticClass: "user-notice" }, [
                          _vm._v("Loading user account..."),
                        ]),
                  ],
            ],
            2
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }